.vr {
    width: 100%;
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    height: 100%;
}

.vr .left {
    text-align: center;
    margin: auto;
    padding: 1rem 4rem;
    max-width: 600px;
}

.vr .left p{
    margin: 1.2rem 0;
}

.vr .right {
    width: 100%;
}









@media screen and (max-width:1140px){
    .vr{
        grid-template-columns: 1fr;
    }

    .vr .right{
        margin-left: 2rem;
        max-width: 80%;
    }
}