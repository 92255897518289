@import url(https://fonts.googleapis.com/css2?family=Changa:wght@200;300;400;500;600;700;800&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 200;
}

body {
  margin: 0;
  font-family: "Changa", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,p,a,h4{
  color: #fff;
  font-weight: 200;
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1.2rem;
  text-transform: uppercase;
  background:transparent;
  cursor: pointer;
  color: #fff;
  border: 1px solid #fff;
  transition: ease .3s all;
}

.btn:hover {
  background: rgba(255, 255, 255, .2);
  transition: ease .3s all;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #333; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, .2);
}
.footer {
    width: 100%;
    padding: 6rem 0;
    background: rgba(0, 0, 0, .9);
}

.footer_container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 40px;
}

.footer .left {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.footer .right{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto;
}


.footer h4 {
    font-weight: 200;
    font-size: 1.4rem;
    padding: 1rem 0;
}

.footer p {
    font-weight: 100;
    font-size: 1.2rem;
    padding: 1rem 0;
}

.footer .location p {
    padding-bottom: .5rem;
}

.footer .location {
    display: flex;
    align-items: center;
}

.footer .location h4 {
    padding-top: 0;
}





@media screen and (max-width:640px){
    .footer_container {
        grid-template-columns: 1fr;
    }

    .right .socials{
        margin: auto;
    }
}
.header {
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
    transition: .5s;
}

.header_bg{
    background-color: rgba(0, 0, 0, .85);
    transition: .5s;
}

.nav_menu{
    display: flex;
}

.nav_menu li {
    padding: 0 1rem;
}

.nav_menu li a {
    font-size: 1.2rem;
    font-weight: 400;
    transition: ease .3s all;
}

.nav_menu li a:hover{
    color: rgb(109, 109, 109);
    transition: ease .3s all;
}

.hamburger {
    display: none;
}

.header span {
    color: red;
}

h1{
    font-weight: 300;
}

@media screen and (max-width:1240px){
    .hamburger{
        display: block;
    }

    .nav_menu{
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        right: -100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: .4s;
        z-index: -1;
        background: rgba(0,0,0, .9);
    }

    .nav_menu.active{
        right: 0;
    }

    .nav_menu li {
        padding: 1rem 0;
    }

    .nav_menu a {
        font-size: 2rem;
    }
}
#video {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -3;
    opacity: 0.2;
}

.hero {
    height: 100vh;
    width: 100%;
}

h1{
    font-weight: 300;
}

.hero .content{
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 40vh;
}

.hero .content h1{
    font-weight: 200;
    font-size: 4rem;
    color: #fff;
}

.hero p{
    font-size: 1.8rem;
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    color: #fff;
}



.content span {
    color: red;
}

@media screen and (max-width:640px){
    .content h1{
        font-size: 3rem;
    }

    .content p {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
    }
}
.hero_img{
    background: rgba(0, 0, 0, .3);
    height: 50vh;
    width: 100%;
    position: relative;
}

.hero_img:before{
    content: "";
    background: url(/static/media/hero.9655e646.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero_img .heading{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.hero_img h1{
    font-size: 2.4rem;
}

.hero_img p {
    font-size: 1.4rem;
}

@media screen and (max-width:640px){
    .hero_img h1 {
        font-size: 2rem;
    }
}
.vr {
    width: 100%;
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    height: 100%;
}

.vr .left {
    text-align: center;
    margin: auto;
    padding: 1rem 4rem;
    max-width: 600px;
}

.vr .left p{
    margin: 1.2rem 0;
}

.vr .right {
    width: 100%;
}

.right .img_container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

.right .img {
    max-width: 60%;
    border: 1px solid #333;
}

.right .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}

.right .bottom{
    grid-column: 4/ -1;
    grid-row: 1;
    z-index: 5;
}

@media screen and (max-width:1140px){
    .vr{
        grid-template-columns: 1fr;
    }

    .vr .img{
        max-width: 80%;
    }
}
label {
    color: #eeee;
}

form{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto;
    max-width: 600px;
}

form label {
    margin-bottom: .5rem;
}

form input, textarea{
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    font-family: "Changa" ,sans-serif;
    background: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
    color: #f4f4f4;
}
.vfx {
    width: 100%;
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    height: 100%;
}

.vfx .left {
    text-align: center;
    margin: auto;
    padding: 1rem 4rem;
    max-width: 600px;
}

.vfx .left p{
    margin: 1.2rem 0;
}

.vfx .right {
    width: 100%;
}

.right .img_container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

.right .img {
    max-width: 60%;
    border: 1px solid #333;
}

.right .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}

.right .bottom{
    grid-column: 4/ -1;
    grid-row: 1;
    z-index: 5;
}

@media screen and (max-width:1140px){
    .vfx{
        grid-template-columns: 1fr;
    }

    .vfx .img{
        max-width: 80%;
    }
}
.metaverse {
    width: 100%;
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    height: 100%;
}

.metaverse .left {
    text-align: center;
    margin: auto;
    padding: 1rem 4rem;
    max-width: 600px;
}

.metaverse .left p{
    margin: 1.2rem 0;
}

.metaverse .right {
    width: 100%;
}

.right .img_container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
}

.right .img {
    max-width: 60%;
    border: 1px solid #333;
}



.right .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}

.right .bottom{
    grid-column: 4/ -1;
    grid-row: 1;
    z-index: 5;
}

@media screen and (max-width:1140px){
    .metaverse{
        grid-template-columns: 1fr;
    }

    .metaverse .img{
        max-width: 80%;
    }
}
.vr {
    width: 100%;
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    height: 100%;
}

.vr .left {
    text-align: center;
    margin: auto;
    padding: 1rem 4rem;
    max-width: 600px;
}

.vr .left p{
    margin: 1.2rem 0;
}

.vr .right {
    width: 100%;
}









@media screen and (max-width:1140px){
    .vr{
        grid-template-columns: 1fr;
    }

    .vr .right{
        margin-left: 2rem;
        max-width: 80%;
    }
}
