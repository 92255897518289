.hero_img{
    background: rgba(0, 0, 0, .3);
    height: 50vh;
    width: 100%;
    position: relative;
}

.hero_img:before{
    content: "";
    background: url("../assets/hero.jpg") no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero_img .heading{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.hero_img h1{
    font-size: 2.4rem;
}

.hero_img p {
    font-size: 1.4rem;
}

@media screen and (max-width:640px){
    .hero_img h1 {
        font-size: 2rem;
    }
}