#video {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -3;
    opacity: 0.2;
}

.hero {
    height: 100vh;
    width: 100%;
}

h1{
    font-weight: 300;
}

.hero .content{
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 40vh;
}

.hero .content h1{
    font-weight: 200;
    font-size: 4rem;
    color: #fff;
}

.hero p{
    font-size: 1.8rem;
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    color: #fff;
}



.content span {
    color: red;
}

@media screen and (max-width:640px){
    .content h1{
        font-size: 3rem;
    }

    .content p {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
    }
}